/* Footer Container */
.footer-container {
    background-color: #220e96;
    color: #fff;
    padding: 20px 0;
}

/* Footer Content */
.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Footer Logo */
.footer-logo {
    display: flex;
    align-items: center;
}

.footer-logo img {
    width: 40px;
    /* Adjust the logo size */
    height: 40px;
    /* Adjust the logo size */
    margin-right: 10px;
}

.footer-logo h2 {
    font-size: 1.5rem;
    margin: 0;
}

/* Footer Links */
.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links ul li {
    margin-bottom: 10px;
}

.footer-links a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #ff6600;
    /* Change the hover color */
}

/* Footer Social Icons */
.footer-social h3 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.footer-social ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.footer-social ul li {
    margin-right: 15px;
}

.footer-social a {
    text-decoration: none;
    color: #fff;
    font-size: 1.25rem;
}

/* Footer Bottom */
.footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #555;
}

.footer-bottom p {
    font-size: 0.875rem;
    margin: 0;
}