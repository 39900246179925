/* SupportedLiving.css */

/* Container for the Supported Living component */
.supported-living {
    padding: 10px 10px;
}

/* Heading for Supported Living Services */
.supported-living h4 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Feature Cards */
.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s;
    
        /* Set a transparent border on all sides except the bottom */
        border: 3px solid transparent;
        border-bottom: none;
        position: relative;
}
.feature-card::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    /* Adjust the height of the bottom border as needed */
    background-image: linear-gradient(to right, #2600ff, #ff8c00);
}

.feature-card:hover {
    transform: scale(1.05);
}

/* Icon for Feature Card */
.feature-icon {
    font-size: 48px;
    color: #007bff;
    /* Blue color, you can adjust this */
}

/* Description for Feature Card */
.feature-description {
    margin-top: 10px;
    font-size: 1rem;
}

/* Container for the Slide Carousel */
.supported-living .react-responsive-carousel {
    margin-top: 20px;
    max-width: 100%;
}

/* Individual Slide */
.react-responsive-carousel .carousel .slide {
    position: relative;
    text-align: center;
}

/* Slide Image */
.react-responsive-carousel .carousel .slide img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Slide Description */
.react-responsive-carousel .carousel .slide p {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
}

/* Carousel container styles */
.carousel-container {
  max-width: 800px; /* Adjust the maximum width as needed */
  margin: 0 auto;
  padding: 20px;
}

/* Individual slide styles */
.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Image styles */
.carousel-image {
  max-width: 100%; /* Ensure images are responsive */
  max-height: 400px; /* Adjust the maximum height as needed */
  object-fit: cover; /* Maintain image aspect ratio */
  border: 2px solid #fff; /* Add a border for better visibility */
  border-radius: 8px;
}

/* Description styles */
.carousel-description {
  margin-top: 10px;
  color: #fff;
}
