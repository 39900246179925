/* Service Cards Container */
.service-cards-container {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    max-width: 1000px;
    padding: 20px;
}

/* Service Card */
.service-card {
    flex: 1;       
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #fff;
    min-width: 300px;
    /* Set a minimum width to create rectangular shapes */
    max-width: 100%;
    /* Allow cards to stretch */
    
}

/* Service Title */
.service-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

/* Service Description */
.service-description {
    font-size: 1rem;
    margin-bottom: 30px;
}

/* Enquire Button */
.enquire-button {
    padding: 10px 20px;
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.enquire-button:hover {
    background-color: #fff;
    color: #333;
}
/* Media Query for Mobile Devices */

@media (max-width: 768px) {
    .service-cards-container {
        flex-direction: column;
        /* Stack cards in a column */
        align-items: center;
        /* Center-align cards */
    }

    .service-card {
        min-width: auto;
        /* Remove minimum width */
        max-width: 100%;
        /* Allow cards to stretch */
    }
}