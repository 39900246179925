.contact-form {
    padding: 20px;

}
/* Add any other styles as needed */
/* style.css */

.container {
    position: relative;
}

.grid-container {
    display: flex;
    justify-content: space-between;
}

.contact-info {
    flex: 1;
}

.contact-map {
    width: 100%;
    position: relative;
}

.contact-map .leaflet-container {
    height: 100%;
}

/* Add any other styles as needed */
#response {
    padding: 25px;
    color: green;
    text-align: center;
    font-size: larger;

}

#submit:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgb(59, 28, 231);
}

.contact {
    padding: 20px;
}

#submit {
    background-color: blue !important;
}
/* Add these styles to your style.css file */

/* Style for the grid container */
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two columns layout */
    gap: 20px;
    /* Adjust the gap between elements */
}

/* Style for the contact info section (left side) */
.contact-info {
    padding: 10px;
    /* Add spacing around the contact info */
    background-color: #f5f5f5;
    /* Background color for the contact info */
    text-align: center;
    /* Center-align the content */
}

/* Style for the contact image section (right side) */
.contact-image {
    padding-top: 10px;
    display: flex;
    align-items: center;
    /* Vertically center the image */
}

/* Adjust the image size if needed */
.contact-image img {
    max-width: 50%;
    /* Set the maximum width to fit the container */
    height: auto;
    /* Automatically adjust the height */
}
/* Existing CSS styles */

/* Add a media query for mobile phones */
@media (max-width: 767px) {
    /* Style for smaller screens (e.g., mobile phones) */

    /* Reset the grid layout to a single column */
    .grid-container {
        grid-template-columns: 1fr;
        /* Single column layout */
    }

    /* Adjust the contact info section (left side) */
    .contact-info {
        padding: 20px;
        /* Increase padding for better spacing */
    }

    /* Adjust the contact image section (right side) */
    .contact-image {
        display: none;
        order: -1;
        /* Change the order to move the image above the contact form */
        text-align: center;
        /* Center-align the image */
    }

    /* Adjust the image size if needed */
    .contact-image img {

        max-width: 100%;

    
        /* Set the maximum width to fit the container */
        height: 200px;
        /* Automatically adjust the height */
    }
}