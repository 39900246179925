.link {
    text-decoration: none;
}

#cta {
    background-image: linear-gradient(to right, #2600ff, #ff8c00);
    margin-top: 5px;
}

.heroGrid {
    color: white;

}

.Divider {
    padding: 30px;
}
.buttonWithPadding {
    margin-top: 7px;
}
@media (max-width: 767px) {
    .heroGrid {
        background-size: cover !important;
        padding: auto;
        /* Adjust the background size for smaller screens */
    }
         .MuiTypography-root {
             /* Adjust the font size for small screens */
             font-size: 1rem !important;
             font-weight: 700;
             font-family: Roboto, sans-serif;
             /* Change this to your preferred small font size */
         }
         .h5h {
            font-weight: 700;
         }
}
