/* Style for the TopHeader */
.top-header {
    background-color: #2600ff;
    /* Background color */
    color: #fff;
    /* Text color */
    text-align: right;
    /* Center-align text */
    padding: 10px;
    /* Add padding for spacing */
    font-size: 14px;
    /* Font size */
    position: fixed;
    /* Fixed position at the top */
    top: 0;
    /* Position at the top of the viewport */
    width: 100%;
    /* Full width */
    z-index: 1000;
    /* Ensure it's above other elements */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
}
.top-header.hidden {
    transform: translateY(-100%);
}
.top-header a {
    color: black;
}
/* Style for the Navbar (adjust the class name to match your actual Navbar) */


/* Media query for smaller screens */
@media (max-width: 767px) {
    .top-header {
        font-size: 12px;
        /* Reduce font size for smaller screens */
    }

    .navbar {
        margin-top: 0;
        /* Reset margin for smaller screens */
    }
}