.navbar {  
    height: auto;
    background-color: rgba(255, 255, 255, 0.664) !important;
    font-weight: bold;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding: 5px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    z-index: 1000;
        /* Higher z-index to ensure it's above the TopHeader */
    margin-top: 40px;
}

.navbar-brand {
    font-size: 35px;
    font-weight: bold;
    background-color: #2600ff;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.logo {
    padding-left: 5px;
    height: 50px;
        /* Adjust the height to your preference */
        width: auto;
        /* This maintains the aspect ratio of the logo */
    
}


.navbar-nav {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.navbar-nav .nav-link {
    border: 1px solid transparent;
    /* Initial border style */
    transition: border-color 0.3s;
    /* Smooth transition effect */
}

.navbar-nav .nav-link:hover {
    border-bottom: solid 5px rgb(24, 17, 215);
    /* Border color on hover */
}
.enquire {
    background-color: #2600ff !important;
}